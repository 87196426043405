exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-tr-js": () => import("./../../../src/pages/404.tr.js" /* webpackChunkName: "component---src-pages-404-tr-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-tr-js": () => import("./../../../src/pages/about.tr.js" /* webpackChunkName: "component---src-pages-about-tr-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-tr-js": () => import("./../../../src/pages/blog.tr.js" /* webpackChunkName: "component---src-pages-blog-tr-js" */),
  "component---src-pages-calendar-en-js": () => import("./../../../src/pages/calendar.en.js" /* webpackChunkName: "component---src-pages-calendar-en-js" */),
  "component---src-pages-calendar-tr-js": () => import("./../../../src/pages/calendar.tr.js" /* webpackChunkName: "component---src-pages-calendar-tr-js" */),
  "component---src-pages-campaigns-en-js": () => import("./../../../src/pages/campaigns.en.js" /* webpackChunkName: "component---src-pages-campaigns-en-js" */),
  "component---src-pages-campaigns-tr-js": () => import("./../../../src/pages/campaigns.tr.js" /* webpackChunkName: "component---src-pages-campaigns-tr-js" */),
  "component---src-pages-community-tr-js": () => import("./../../../src/pages/community.tr.js" /* webpackChunkName: "component---src-pages-community-tr-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-tr-js": () => import("./../../../src/pages/contact.tr.js" /* webpackChunkName: "component---src-pages-contact-tr-js" */),
  "component---src-pages-cookie-policy-en-js": () => import("./../../../src/pages/cookie-policy.en.js" /* webpackChunkName: "component---src-pages-cookie-policy-en-js" */),
  "component---src-pages-cookie-policy-tr-js": () => import("./../../../src/pages/cookie-policy.tr.js" /* webpackChunkName: "component---src-pages-cookie-policy-tr-js" */),
  "component---src-pages-help-en-js": () => import("./../../../src/pages/help.en.js" /* webpackChunkName: "component---src-pages-help-en-js" */),
  "component---src-pages-help-tr-js": () => import("./../../../src/pages/help.tr.js" /* webpackChunkName: "component---src-pages-help-tr-js" */),
  "component---src-pages-how-en-js": () => import("./../../../src/pages/how.en.js" /* webpackChunkName: "component---src-pages-how-en-js" */),
  "component---src-pages-how-tr-js": () => import("./../../../src/pages/how.tr.js" /* webpackChunkName: "component---src-pages-how-tr-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-tr-js": () => import("./../../../src/pages/index.tr.js" /* webpackChunkName: "component---src-pages-index-tr-js" */),
  "component---src-pages-invitation-en-js": () => import("./../../../src/pages/invitation.en.js" /* webpackChunkName: "component---src-pages-invitation-en-js" */),
  "component---src-pages-invitation-tr-js": () => import("./../../../src/pages/invitation.tr.js" /* webpackChunkName: "component---src-pages-invitation-tr-js" */),
  "component---src-pages-invite-en-js": () => import("./../../../src/pages/invite.en.js" /* webpackChunkName: "component---src-pages-invite-en-js" */),
  "component---src-pages-invite-tr-js": () => import("./../../../src/pages/invite.tr.js" /* webpackChunkName: "component---src-pages-invite-tr-js" */),
  "component---src-pages-join-en-js": () => import("./../../../src/pages/join.en.js" /* webpackChunkName: "component---src-pages-join-en-js" */),
  "component---src-pages-join-tr-js": () => import("./../../../src/pages/join.tr.js" /* webpackChunkName: "component---src-pages-join-tr-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-tr-js": () => import("./../../../src/pages/privacy-policy.tr.js" /* webpackChunkName: "component---src-pages-privacy-policy-tr-js" */),
  "component---src-pages-product-circle-rules-en-js": () => import("./../../../src/pages/productCircleRules.en.js" /* webpackChunkName: "component---src-pages-product-circle-rules-en-js" */),
  "component---src-pages-product-circle-rules-tr-js": () => import("./../../../src/pages/productCircleRules.tr.js" /* webpackChunkName: "component---src-pages-product-circle-rules-tr-js" */),
  "component---src-pages-redirect-tr-js": () => import("./../../../src/pages/redirect.tr.js" /* webpackChunkName: "component---src-pages-redirect-tr-js" */),
  "component---src-pages-register-en-js": () => import("./../../../src/pages/register.en.js" /* webpackChunkName: "component---src-pages-register-en-js" */),
  "component---src-pages-register-tr-js": () => import("./../../../src/pages/register.tr.js" /* webpackChunkName: "component---src-pages-register-tr-js" */),
  "component---src-pages-reset-password-en-js": () => import("./../../../src/pages/reset-password.en.js" /* webpackChunkName: "component---src-pages-reset-password-en-js" */),
  "component---src-pages-reset-password-tr-js": () => import("./../../../src/pages/reset-password.tr.js" /* webpackChunkName: "component---src-pages-reset-password-tr-js" */),
  "component---src-pages-rules-en-js": () => import("./../../../src/pages/rules.en.js" /* webpackChunkName: "component---src-pages-rules-en-js" */),
  "component---src-pages-rules-tr-js": () => import("./../../../src/pages/rules.tr.js" /* webpackChunkName: "component---src-pages-rules-tr-js" */),
  "component---src-pages-success-en-js": () => import("./../../../src/pages/success.en.js" /* webpackChunkName: "component---src-pages-success-en-js" */),
  "component---src-pages-success-tr-js": () => import("./../../../src/pages/success.tr.js" /* webpackChunkName: "component---src-pages-success-tr-js" */),
  "component---src-pages-user-agreement-en-js": () => import("./../../../src/pages/user-agreement.en.js" /* webpackChunkName: "component---src-pages-user-agreement-en-js" */),
  "component---src-pages-user-agreement-tr-js": () => import("./../../../src/pages/user-agreement.tr.js" /* webpackChunkName: "component---src-pages-user-agreement-tr-js" */),
  "component---src-pages-verify-en-js": () => import("./../../../src/pages/verify.en.js" /* webpackChunkName: "component---src-pages-verify-en-js" */),
  "component---src-pages-verify-tr-js": () => import("./../../../src/pages/verify.tr.js" /* webpackChunkName: "component---src-pages-verify-tr-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

